import { Injectable } from '@angular/core'
import { RouteGuard } from '@src/app/core/services/route-guard.service'
import { ManageAccountPage, Page } from '@src/app/shared/typings/enum/pages'

@Injectable({ providedIn: 'root' })
export class CanActivateAccountPage extends RouteGuard {
  override page = ManageAccountPage.account

  canActivate(): boolean {
    console.info('Activating Account Page')

    const requirements = [this.hasAccountId(), this.hasSession()]
    if (!requirements.every(Boolean)) {
      void this.navService.goToPage([ManageAccountPage.account, Page.login])
      return false
    }

    if (!this.purchasedSubscription()) {
      // Go to the first step in the flow after the quiz
      void this.navService.goToPage([Page.measuringDevice])
      return false
    }

    return true
  }
}
